import * as React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Master from 'layouts/Master';
import ModelList from 'components/ModelList';
import Model from 'models/Model';
import Config from 'config/Config';

type Data = {
  pageData: {
    models: Model[];
  };
};

type Props = {
  data: Data;
}

export default class NotFoundPage extends React.Component<Props> {
  render() {
    return (
      <Master>
        <Helmet title={`${Config.SiteTitle} | ${Config.SiteDescription}`} />

        <div className="container">
          <h1 className="not-found-page__title">
            We're sorry, we can't find the page you're looking for 😔
          </h1>

          <h2 className="not-found-page__subtitle">
            In the meantime you can pick one of ours selected big tits camgirl
          </h2>

          <p className="not-found-page__section-title">
            You are spoiled for choice 😏
          </p>

          <ModelList models={this.props.data.pageData.models} />
        </div>
      </Master>
    );
  }
}

export const pageQuery = graphql`
  {
    pageData: allCamGirl {
      models: nodes {
        username
        id
        followersCount
      }
    }
  }
`;